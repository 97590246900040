<template>
  <div>
    <div style="padding: 35px; padding-top: 15px; padding-bottom: 15px">
      <transition name="tour-guide-fade">
        <div v-if="showTitles" class="pt-5 pb-3 text-2xl font-weight-bold">
          {{ $t('we_set_when_each_round_will_sent') }}
        </div>
      </transition>
      <transition name="tour-guide-fade">
        <div v-if="showTitles" class="text-xl pb-2">
          {{ $t('edit_timing') }}
        </div>
      </transition>
    </div>
    <transition-group name="tour-guide-slide" tag="div" class="pa-5 pt-0 message-container">
      <v-card v-for="time in displayedTimingOptions" :key="`time_${time.id}`" class="schedule-card">
        <div class="card-content">
          <div class="date">
            <div class="day">
              {{ getDay(time.startFrom) }}
            </div>
            <div class="month">
              {{ getMonth(time.startFrom, $i18n.locale) }}
            </div>
          </div>
          <div class="divider"></div>
          <div class="details">
            <div class="title">
              {{ searchType(time.type) }} {{ time.type === 4 || time.type === 1 ? $t('round') + (time.round + 1) : '' }}
            </div>
            <div class="clock d-flex">
              <div>
                <v-icon color="primary" size="20">
                  {{ icons.mdiClockOutline }}
                </v-icon>
              </div>
              <div class="mx-1">{{ $t('startFrom') }}{{ getTime(time.startFrom) }}</div>
            </div>
          </div>
        </div>
      </v-card>
    </transition-group>
  </div>
</template>

<script>
import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
import { mdiClockOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import moment from 'moment'
import { useTourGuideTitleAnimation } from '../useTourGuideTitleAnimation'

export default {
  name: 'Timing',
  props: {
    currentStep: {
      required: true,
    },
    stepIndex: {
      required: true,
    },
  },
  setup(props) {
    const timingOptions = ref([])
    const displayedTimingOptions = ref([])
    let animationInterval

    const displayTimingSequentially = () => {
      let index = 0
      animationInterval = setInterval(() => {
        if (index < timingOptions.value.length) {
          displayedTimingOptions.value.push(timingOptions.value[index])
          index += 1
        } else {
          clearInterval(animationInterval)
        }
      }, 150) // Show each timing option after 0.1 seconds
    }

    const { showTitles } = useTourGuideTitleAnimation(props, displayTimingSequentially, () => {
      clearInterval(animationInterval)
      displayedTimingOptions.value.splice(0, displayedTimingOptions.value.length)
    })

    const getTiming = () => {
      store.dispatch('fetchTiming', { id: store.state.eventData.id }).then(res => {
        timingOptions.value = res.data.map((item, index) => ({
          ...item,
          id: item.id || index.toString(),
        }))
      })
    }

    const getDay = date => moment(date).format('D')

    const getMonth = (date, lang = 'en') => {
      moment.locale(lang)
      return moment(date).format('MMM').toUpperCase()
    }

    const getTime = date => moment(date).format('HH:mm')

    const searchType = type => {
      if (type === null) return ''
      if (type === 1) return i18n.t('invitationMessage')
      if (type === 2) return i18n.t('DirectionMessage')
      if (type === 3) return i18n.t('ThanksMsg')
      if (type === 4) return i18n.t('call')
    }

    getTiming()

    return {
      displayedTimingOptions,
      getDay,
      getMonth,
      getTime,
      searchType,
      icons: {
        mdiClockOutline,
      },
      showTitles,
    }
  },
}
</script>

<style scoped>
.schedule-card {
  display: flex;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: #ffffff;
}

.card-content {
  display: flex;
  align-items: center;
}

.date {
  text-align: center;
  width: 70px;
}

.day {
  font-size: 2rem;
  font-weight: bold;
}

.month {
  font-size: 1rem;
  margin-top: -5px;
}

.details {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  padding-left: 10px;
}

.title {
  font-size: 1rem !important;
  font-weight: bold;
  margin-bottom: 5px;
}

.clock {
  font-size: 1rem;
  color: #888;
}

.divider {
  border-left: 1px solid #9155fd;
  height: 100%;
}
</style>
